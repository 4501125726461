.home-wrap {
  padding-top: 100vh;
  position: relative; }
  .home-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 0; }
    .home-wrap .overlay img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0; }
    .home-wrap .overlay .content {
      position: relative;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%; }
      @media only screen and (max-width: 768px) {
        .home-wrap .overlay .content {
          padding: 0 32px; } }
      .home-wrap .overlay .content button {
        background-color: #041926;
        border-radius: 30px;
        border: 0;
        outline: none;
        color: #FFFFFF;
        font-size: 18px;
        padding: 18px 45px; }
        @media only screen and (max-width: 768px) {
          .home-wrap .overlay .content button {
            padding: 10px 16px; } }
      .home-wrap .overlay .content h1 {
        font-size: 72px;
        font-weight: 600;
        color: #041926;
        text-align: center; }
        @media only screen and (max-width: 768px) {
          .home-wrap .overlay .content h1 {
            font-size: 32px; } }
      .home-wrap .overlay .content h6 {
        font-size: 20px;
        font-weight: 400;
        color: #041926;
        max-width: 690px;
        text-align: center;
        margin-bottom: 52px; }
        @media only screen and (max-width: 768px) {
          .home-wrap .overlay .content h6 {
            font-size: 16px; } }
  .home-wrap header {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .home-wrap header {
        justify-content: center; } }
    .home-wrap header .navigation {
      width: 100%;
      padding: 0 150px;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .home-wrap header .navigation {
          display: none; } }
      .home-wrap header .navigation ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: 18px;
        font-weight: 600; }
        .home-wrap header .navigation ul a {
          text-decoration: none;
          color: #041926; }
    .home-wrap header .social {
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 18px;
      font-weight: 600; }
      @media only screen and (max-width: 768px) {
        .home-wrap header .social {
          display: none; } }
      .home-wrap header .social a {
        margin-left: 10px; }
  .home-wrap .templates {
    padding: 80px 0; }
    @media only screen and (max-width: 768px) {
      .home-wrap .templates {
        padding: 40px 0; } }
    .home-wrap .templates .head {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .home-wrap .templates .head {
          flex-direction: column; } }
      .home-wrap .templates .head h3 {
        color: #041926;
        font-size: 22px;
        font-weight: 600; }
        .home-wrap .templates .head h3 span {
          font-weight: 300; }
      .home-wrap .templates .head .sort {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .home-wrap .templates .head .sort span {
          font-size: 22px;
          font-weight: 300;
          color: #041926; }
          @media only screen and (max-width: 768px) {
            .home-wrap .templates .head .sort span {
              font-size: 18px; } }
        .home-wrap .templates .head .sort .select {
          margin-left: 20px;
          position: relative; }
          .home-wrap .templates .head .sort .select button {
            position: relative;
            border: 1px solid #041926;
            outline: none;
            border-radius: 28px;
            color: #041926;
            font-size: 22px;
            font-weight: 600;
            background-color: #FFFFFF;
            width: 210px;
            text-align: left;
            padding: 10px 20px; }
            @media only screen and (max-width: 768px) {
              .home-wrap .templates .head .sort .select button {
                width: 160px; } }
            .home-wrap .templates .head .sort .select button::after {
              content: ' ';
              display: block;
              border-top: 2px solid #041926;
              border-left: 2px solid #041926;
              width: 17px;
              height: 17px;
              transform: rotate(-135deg);
              position: absolute;
              top: 15px;
              right: 20px; }
          .home-wrap .templates .head .sort .select .menu {
            z-index: 1;
            position: absolute;
            background-color: #FFFFFF;
            width: 100%;
            top: 100%;
            margin-top: 10px;
            list-style: none;
            padding: 16px;
            -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
            color: #041926;
            font-size: 22px;
            font-weight: 300; }
            .home-wrap .templates .head .sort .select .menu li {
              cursor: pointer; }
    .home-wrap .templates .items {
      padding-top: 45px; }
      .home-wrap .templates .items article {
        width: 100%;
        -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        position: relative;
        cursor: pointer;
        margin-bottom: 40px; }
        .home-wrap .templates .items article .content-overlay {
          border: 0;
          background-color: transparent;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 350px;
          align-items: center;
          justify-content: center; }
          .home-wrap .templates .items article .content-overlay .overlay {
            background-image: linear-gradient(to right, rgba(14, 73, 82, 0.5), rgba(249, 249, 182, 0.5)) !important; }
          @media only screen and (max-width: 768px) {
            .home-wrap .templates .items article .content-overlay {
              height: 150px; } }
          .home-wrap .templates .items article .content-overlay .content {
            background-color: #FFFFFF;
            border-radius: 23px;
            padding: 0; }
            .home-wrap .templates .items article .content-overlay .content button {
              border: 0;
              outline: none;
              color: #041926;
              background-color: #FFFFFF;
              border-radius: 23px;
              color: #041926;
              font-size: 18px;
              font-weight: 600;
              padding: 10px 25px;
              z-index: 999; }
              @media only screen and (max-width: 768px) {
                .home-wrap .templates .items article .content-overlay .content button {
                  padding: 8px 16px;
                  font-size: 16px; } }
        .home-wrap .templates .items article:hover .content-overlay {
          display: flex; }
        .home-wrap .templates .items article img {
          width: 100%;
          height: 350px;
          object-fit: cover; }
          @media only screen and (max-width: 768px) {
            .home-wrap .templates .items article img {
              height: 150px; } }
        .home-wrap .templates .items article .content {
          padding: 20px 20px 25px 20px;
          background-color: #FFFFFF; }
          @media only screen and (max-width: 768px) {
            .home-wrap .templates .items article .content {
              padding: 16px 16px 20px 16px; } }
          .home-wrap .templates .items article .content h6 {
            color: #041926;
            font-size: 18px;
            font-weight: 600; }
            @media only screen and (max-width: 768px) {
              .home-wrap .templates .items article .content h6 {
                font-size: 16px; } }
          .home-wrap .templates .items article .content p {
            font-size: 14px;
            color: #041926;
            font-weight: 300;
            margin-bottom: 0; }
            @media only screen and (max-width: 768px) {
              .home-wrap .templates .items article .content p {
                font-size: 12px; } }
  .home-wrap .reviews {
    padding: 20px 0; }
    @media only screen and (max-width: 768px) {
      .home-wrap .reviews {
        padding: 0; } }
    .home-wrap .reviews .left h3 {
      color: #041926;
      font-size: 32px;
      font-weight: 300; }
      .home-wrap .reviews .left h3 span {
        font-weight: 600; }
    .home-wrap .reviews .left p {
      color: #989898;
      font-size: 18px;
      font-weight: 300; }
    .home-wrap .reviews .review {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 18px 35px;
      -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.05); }
      .home-wrap .reviews .review > p {
        color: #757575;
        font-size: 16px;
        font-weight: 300;
        border-left: 4px solid #EBCC10;
        padding-left: 20px;
        margin-top: 20px; }
      .home-wrap .reviews .review .user {
        margin-top: 34px; }
        .home-wrap .reviews .review .user img {
          width: 46px;
          height: 46px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 20px; }
        .home-wrap .reviews .review .user span {
          color: #041926;
          font-size: 18px;
          font-weight: 600; }
    .home-wrap .reviews .slider-container {
      position: relative;
      padding-right: 0;
      padding-left: 0; }
      .home-wrap .reviews .slider-container .slick-arrow {
        background-color: #EBCC10;
        outline: none;
        border: 0;
        color: transparent;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: absolute; }
        @media only screen and (max-width: 768px) {
          .home-wrap .reviews .slider-container .slick-arrow {
            display: none; } }
      .home-wrap .reviews .slider-container .slick-next {
        bottom: 0;
        left: -50%;
        margin-left: 100px; }
        .home-wrap .reviews .slider-container .slick-next::after {
          content: ' ';
          display: block;
          border-top: 2px solid #041926;
          border-left: 2px solid #041926;
          width: 17px;
          height: 17px;
          transform: rotate(135deg);
          position: absolute;
          top: 25px;
          left: 25px; }
      .home-wrap .reviews .slider-container .slick-prev {
        bottom: 0;
        left: -50%;
        margin-left: 15px; }
        .home-wrap .reviews .slider-container .slick-prev::after {
          content: ' ';
          display: block;
          border-top: 2px solid #041926;
          border-left: 2px solid #041926;
          width: 17px;
          height: 17px;
          transform: rotate(-45deg);
          position: absolute;
          top: 25px;
          left: 25px; }
      .home-wrap .reviews .slider-container .slick-slider {
        overflow: hidden; }
      .home-wrap .reviews .slider-container .slick-list .slick-slide {
        float: left; }
        .home-wrap .reviews .slider-container .slick-list .slick-slide > div {
          padding: 0 15px; }
  .home-wrap .bottom-section {
    margin-bottom: -80px;
    padding-top: 90px; }
    @media only screen and (max-width: 768px) {
      .home-wrap .bottom-section {
        display: none; } }
    .home-wrap .bottom-section .top {
      background-color: #FFFFFF;
      border-radius: 10px;
      position: relative;
      height: 160px;
      padding: 36px 39px; }
      .home-wrap .bottom-section .top h3 {
        color: #041926;
        font-size: 32px;
        font-weight: 300; }
        .home-wrap .bottom-section .top h3 span {
          font-weight: 600; }
      .home-wrap .bottom-section .top button {
        background-color: #EBCC10;
        color: #FFFFFF;
        border: 0;
        outline: none;
        border-radius: 10px;
        padding: 16px 45px;
        font-size: 18px;
        font-weight: 600; }
      .home-wrap .bottom-section .top .image {
        position: absolute;
        right: 0;
        top: -50px; }
  .home-wrap footer {
    background-color: #093B4B;
    padding-top: 158px; }
    @media only screen and (max-width: 768px) {
      .home-wrap footer {
        padding-top: 40px;
        margin-top: 32px; } }
    .home-wrap footer p {
      color: #FFFFFF;
      font-size: 16px; }
    .home-wrap footer h3 {
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      margin-bottom: 27px; }
    .home-wrap footer ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .home-wrap footer ul li a {
        color: #FFFFFF; }
    .home-wrap footer .contact-item {
      position: relative;
      padding-left: 32px;
      color: #FFFFFF;
      margin-bottom: 20px; }
      .home-wrap footer .contact-item .isvg {
        position: absolute;
        left: 0; }
    .home-wrap footer .social a {
      margin-right: 25px; }
    .home-wrap footer .spacer {
      height: 1px;
      background-color: #FFFFFF; }
    .home-wrap footer .copyright {
      padding-top: 25px;
      padding-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 300; }
      .home-wrap footer .copyright p {
        margin-bottom: 0; }
      .home-wrap footer .copyright a {
        color: #FFFFFF;
        font-weight: 600; }

.order-now-btn {
  border: 0;
  border-radius: 10px;
  color: #FFFFFF;
  padding: 10px 20px;
  font-weight: 600;
  background-color: #EBCC10; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background-color: #F4F7F6; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Poppins', sans-serif; }

button {
  cursor: pointer; }

.dashboard-wrap {
  /*header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
        border-bottom: 1px solid #B8B8B8;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #F4F7F6;
        z-index: 9999;

        button{
            background-color: $black;
            font-size: 18px;
            font-weight: $semiBold;
            color: $white;
            border-radius: 20px;
            border: 0;
            outline: none;
            padding: 8px 24px;
        }
    }*/
  /* width */
  /* Track */
  /* Handle */ }
  .dashboard-wrap header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url("./assets/bg.png"); }
    @media only screen and (max-width: 768px) {
      .dashboard-wrap header {
        justify-content: center; } }
    .dashboard-wrap header .navigation {
      width: 100%;
      padding: 0 150px;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .dashboard-wrap header .navigation {
          display: none; } }
      .dashboard-wrap header .navigation ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: 18px;
        font-weight: 600; }
        .dashboard-wrap header .navigation ul a {
          text-decoration: none;
          color: #041926; }
    .dashboard-wrap header .social {
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 18px;
      font-weight: 600; }
      @media only screen and (max-width: 768px) {
        .dashboard-wrap header .social {
          display: none; } }
      .dashboard-wrap header .social a {
        margin-left: 10px; }
  .dashboard-wrap .toolbox {
    position: fixed;
    top: 89px;
    left: 0;
    border-right: 1px solid #B8B8B8;
    height: 100%;
    background-color: #F4F7F6;
    z-index: 9; }
    .dashboard-wrap .toolbox ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 20px; }
      .dashboard-wrap .toolbox ul li {
        padding: 20px 15px;
        cursor: pointer; }
      .dashboard-wrap .toolbox ul .active {
        border: 1px solid #70CFA6; }
  .dashboard-wrap .sidebar {
    position: fixed;
    top: 89px;
    right: 0;
    border-left: 1px solid #B8B8B8;
    height: 100%;
    height: calc(100% - 150px);
    background-color: #F4F7F6;
    padding: 20px  15px;
    width: 24%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .dashboard-wrap .sidebar ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .dashboard-wrap .sidebar ul li {
        width: 100%;
        position: relative; }
        .dashboard-wrap .sidebar ul li input {
          background-color: #FFFFFF;
          margin-bottom: 15px;
          border-radius: 8px;
          border: 0;
          outline: none;
          width: auto;
          color: #041926;
          padding: 11px 20px;
          width: 100%;
          font-weight: 600; }
          .dashboard-wrap .sidebar ul li input:hover {
            background-color: #70CFA6;
            color: #FFFFFF; }
        .dashboard-wrap .sidebar ul li > button {
          background-color: transparent;
          outline: none;
          border: 0;
          position: absolute;
          top: 8px;
          right: 8px; }
        .dashboard-wrap .sidebar ul li div {
          background-color: transparent;
          margin-bottom: 15px;
          border-radius: 8px;
          border: 1px solid #B8B8B8;
          outline: none;
          width: auto;
          color: #041926;
          padding: 11px 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .dashboard-wrap .sidebar ul li div button {
            background-color: transparent;
            outline: none;
            border: 0; }
          .dashboard-wrap .sidebar ul li div input {
            background-color: transparent;
            margin-bottom: 0;
            width: auto;
            padding: 11px 5px;
            color: #041926; }
            .dashboard-wrap .sidebar ul li div input:hover {
              background-color: transparent;
              color: #041926; }
        .dashboard-wrap .sidebar ul li ul {
          padding-left: 30px;
          position: relative; }
          .dashboard-wrap .sidebar ul li ul li {
            position: relative; }
            .dashboard-wrap .sidebar ul li ul li:first-child::after {
              content: ' ';
              display: block;
              width: 21px;
              height: 21px;
              border-left: 2px solid #70CFA6;
              border-bottom: 2px solid #70CFA6;
              position: absolute;
              top: 0;
              left: -30px; }
      .dashboard-wrap .sidebar ul .active > input {
        background-color: #70CFA6;
        color: #FFFFFF; }
      .dashboard-wrap .sidebar ul .active ul .active > input {
        background-color: #70CFA6;
        color: #FFFFFF; }
    .dashboard-wrap .sidebar .form .input-wrap input {
      background-color: #FFFFFF;
      margin-bottom: 15px;
      border-radius: 8px;
      border: 0;
      outline: none;
      width: auto;
      color: #041926;
      padding: 11px 20px;
      width: 100%;
      font-weight: 600; }
    .dashboard-wrap .sidebar .form .input-wrap ::-webkit-input-placeholder {
      /* Edge */
      color: #777;
      font-weight: 400; }
    .dashboard-wrap .sidebar .form .input-wrap :-ms-input-placeholder {
      /* Internet Explorer */
      color: #777;
      font-weight: 400; }
    .dashboard-wrap .sidebar .form .input-wrap ::placeholder {
      color: #777;
      font-weight: 400; }
    .dashboard-wrap .sidebar .button {
      background-color: #041926;
      color: #FFFFFF;
      padding: 12px 20px;
      border: 0;
      outline: none;
      border-radius: 8px;
      width: 100%; }
  .dashboard-wrap .area {
    width: 54%;
    padding-top: 104px;
    padding-left: 100px;
    float: left;
    position: relative; }
    .dashboard-wrap .area img {
      width: 100%;
      position: absolute;
      top: 190px;
      left: 100px;
      width: calc(100% - 100px); }
    .dashboard-wrap .area > div {
      z-index: 99; }
  .dashboard-wrap .right-area {
    width: 20%;
    float: left;
    position: fixed;
    top: 104px;
    left: 54%; }
    .dashboard-wrap .right-area h3 {
      font-size: 24px;
      font-weight: 300;
      padding-left: 36px; }
    .dashboard-wrap .right-area .templates {
      height: 400px;
      overflow-y: auto;
      margin-bottom: 20px;
      padding-left: 36px;
      padding-right: 36px; }
      .dashboard-wrap .right-area .templates .image {
        width: 50%;
        float: left;
        padding: 7.5px;
        cursor: pointer; }
        .dashboard-wrap .right-area .templates .image img {
          width: 100%;
          height: 200px;
          object-fit: cover; }
    .dashboard-wrap .right-area .notes {
      height: 600px;
      overflow-y: auto;
      padding-left: 36px;
      padding-right: 36px; }
      .dashboard-wrap .right-area .notes .note {
        border-radius: 6px;
        -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
        margin-bottom: 15px; }
        .dashboard-wrap .right-area .notes .note textarea {
          background-color: transparent;
          resize: none;
          border: 0;
          outline: none;
          font-size: 20px;
          color: #041926;
          padding: 16px 30px;
          width: 100%;
          height: 108px; }
  .dashboard-wrap ::-webkit-scrollbar {
    width: 10px; }
  .dashboard-wrap ::-webkit-scrollbar-track {
    background: #EEEEEE;
    border-radius: 17px; }
  .dashboard-wrap ::-webkit-scrollbar-thumb {
    background: #70CFA6;
    border-radius: 17px; }

.button {
  position: relative;
  overflow: hidden; }
  .button .overlay {
    position: absolute;
    width: 1000px;
    height: 1000px;
    margin-left: -500px;
    margin-top: -500px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #0e4952, #f9f9b6);
    -webkit-animation-name: grow;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: grow;
    animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 1000px;
    height: 1000px;
    margin-left: -500px;
    margin-top: -500px; } }

@keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 1000px;
    height: 1000px;
    margin-left: -500px;
    margin-top: -500px; } }
  .button .content {
    z-index: 1;
    position: relative; }
